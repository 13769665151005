<template>
  <div>
    <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
    <span class="leading-none font-medium">Amenity Icon</span>

    <file-pond
        name="images"
        ref="pond"
        label-idle="Drop Icon here..."
        :allow-multiple="false"
        accepted-file-types="image/jpeg, image/png"
        instant-upload="false"
        :files="form.images != null ? form.images[0] : []"
        @addfile="handleFileAdd"/>
    <span class="text-danger text-sm" v-show="validation.images?true:false">
    {{ validation.images?validation.images[0]:''}}
    </span>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
// Import FilePond
import vueFilePond from 'vue-filepond';

// Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';

// Import styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
const FilePond = vueFilePond( FilePondPluginFileValidateType, FilePondPluginImagePreview );

export default {
  components: {
    FilePond
  },
  computed: {
    ...mapGetters({
        form: 'amenity/getFormObj',
        images: 'amenity/getImages',
        validation: 'validation/getValidationErrors',
    })
  },
  methods: {
    ...mapActions('amenity', [
      'updateForm'
    ]),
    handleFileAdd(image_name, files) {
      this.updateForm({key: 'images', value: files.file})
    },
  }
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
