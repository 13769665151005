<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Amenity Details</span>
      </div>
      <div class="vx-col w-full">
        <vs-input 
          class="w-full mt-4" 
          label="Name" 
          :danger="validation.name?true:false"
          :danger-text="validation.name?validation.name[0]:''"
          :value="form.name"
          @input="updateForm({key: 'name', value: $event})" />
      </div>
      <div class="vx-col w-full md:w-3/3">
        <vs-input 
          class="w-full mt-4" 
          label="Display Name" 
          :danger="validation.display_name?true:false"
          :danger-text="validation.display_name?validation.display_name[0]:''"
          :value="form.display_name"
          @input="updateForm({key: 'display_name', value: $event})" />
      </div>
    <div class="vx-col w-full md:w-3/3">
      <label class=" vs-input--label">Type</label>
        <v-select 
          :danger="validation.type?true:false"
          :value="dropdown.type"
          @input="updateForm({key: 'type', value: $event})"
          :options="types" />
          <span class="text-danger text-sm" 
        v-show="validation.type?true:false">
        {{ validation.type?validation.type[0]:''}}
      </span>
  </div>
  </div>
  </div>
</template>

<script>

import vSelect,{vOption} from 'vue-select'
import { mapGetters,mapActions } from 'vuex'

export default {  
  components: {
    vSelect,
    vOption
  },

  computed: {
    ...mapGetters({
        form: 'amenity/getFormObj',
        types: 'amenity/getAmenityTypes',
        dropdown: 'amenity/getDropdownObj',
        validation: 'validation/getValidationErrors',
    })
  },
  methods: {
    ...mapActions({
      updateForm: 'amenity/updateForm',
    })
  }
}
</script>
